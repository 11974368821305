import logo from './logo.svg';
import './App.css';
import { BrowserRouter,Route, Routes } from "react-router-dom";
import routeConfig from "./utils/routeConfig";
import {baseRoute} from "./utils/config";
import Inventory from './inventory/index';
function  goto(){
  this.props.history.push('/inventory')
}

function App() {
  return (
      <div className="App">
        <BrowserRouter basename={baseRoute}>
          <Routes>
            {
              routeConfig().map(router => {
                return (
                    <Route
                        exact={true}
                        key={router.path}
                        path={router.path}
                        element={router.component}
                    ></Route>
                )
              })
            }
          </Routes>
        </BrowserRouter>

      </div>
  );
}

export default App;
