import React from 'react';
import './index.css'
import {removeUserInfo} from '../utils/wechat'

class Login extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    refresh = () =>{
        removeUserInfo();
        this.props.history.push('/login');
    }

    render() {
        return (
            <div className={'mainPage'}>
                <div className={"header"}>
                    <div className={"logo"}>
                        <img src={"/storage/logo.png"}/>
                    </div>
                </div>
                <div className={'alertMsgPart'}>
                    <h3>
                        您当前没有权限查看，请联系阿诗特人员授权！
                    </h3>
                </div>
                <div className={"bottomLine"}><button className={"btnRefresh2"} onClick={this.refresh}>刷新重试</button></div>
            </div>
        );
    }
}

export default Login