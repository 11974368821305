import axios from 'axios';
import {getToken,removeToken} from './auth'
import {wechatAuth, hasRight} from './wechat';

import {
    TIMEOUT,
    baseURL
} from "./config";

const instance = axios.create({
    timeout: TIMEOUT,
    baseURL: baseURL
})
instance.interceptors.request.use(async config => {
    // 1.发送网络请求时，在页面中添加一个loading组件作为动画;
    let _token = getToken();
    // if(!hasRight()){
    //     window.location.href = window.location.origin + '/getRight';
    // }
    if (_token) {
        config.headers['Rct-Token'] = _token; // 让每个请求携带自定义token 请根据实际情况自行修改 // change by wen hao
        config.headers['Content-Type'] = 'application/json';
        config.headers['Cache-Control'] = 'no-cache';
    }
    if(config.url!='/user/get'){
        if(!await wechatAuth()){
            Promise.reject('没有权限');
            // alert('没有权限')
            return false
        }
        if(!hasRight()){
            window._ROUTER_.push('getRight');
        }
    }

    // 2.某些网络请求要求用户必须登录，可以在请求中判断是否携带了token，没有携带token直接跳转到login页面；
    // 3.对某些请求参数进行序列化；
    return config;
}, err => {
    return err;
})

instance.interceptors.response.use(response => {
    if(response.data&&response.data.code===1001){
        removeToken();
        window.location.href = window.location.origin + '/login';
    }
    return response.data;
}, err => {
    if (err && err.response) {
        if (err.response.data && err.response.data.code === 1001) {
            removeToken();
            window.location.href = window.location.origin + '/login';
        }

        switch (err.response.status) {
            case 400:
                err.message = "请求错误";
                break;
            case 401:
                err.message = "未授权访问";
                removeToken();
                window.location.href = window.location.origin + '/login';
                break;
        }
    }
    return err;
})

export default instance;