import Inventory from '../inventory';
import Login from '../login'; //登录
import GetRight from '../getRight'; //登录
import DetailList from "../inventory/detailList";
import { baseRoute } from "./config";
const routesConfig = () => [
    {
        path: `/`,
        component: <Inventory />
    },
    {
        path: `/storage`,
        component: <Inventory />
    },
    {
        path: `/inventory`,
        component: <Inventory />
    },
    {
        path: `/login`,
        component: <Login />
    }
    ,
    {
        path: `/getRight`,
        component: <GetRight />
    },
    {
        path: `/detailList`,
        component: <DetailList />
    }
];

export default routesConfig;