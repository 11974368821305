import React from 'react';
import './index.css'
import {getInventoryInfoDetail} from '../server/api'

class Inventory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 1,
            pageSize: 10,
            dataTimeNow: this.getDateStrDiff(0),
            total: 0,
            records: [],
            gdsMtno: this.props.location.state.gdsMtno
            //     [{
            //     id: 0,
            //     passPortNo: '',
            //     vehicleNo: '',
            //     containerNo: '',
            //     passTime: '',
            //     gdsMtno: '',
            //     gdsNm: '',
            //     gdecd: '',
            //     dcQty: 0,
            //     ctype: 0,
            //     productName: '',
            // }]
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData(this.state.current, this.state.pageSize);
    }

    componentWillUnmount() {
    }

    getData(current, pageSize) {
        getInventoryInfoDetail(this.state.gdsMtno, current, pageSize).then((data) => {
            if (data && data.code == 0) {
                this.setState({
                    records: data.result.records,
                    total: data.result.total,
                    current: data.result.current,
                    pageSize: data.result.size
                })
            }
        })
    }

    changeSize = (event) => {
        const pageSize = event.target.value;
        this.getData(1, pageSize);
    }

    goPrev = () => {
        let index =this.state.current-1;
        if(index<=1){
            index =1
        }
        this.getData(index, this.state.pageSize);
    }

    goNext = () => {
        let index = this.state.current + 1;
        let maxPage = Math.ceil(this.state.total / this.state.pageSize);
        if (index > maxPage) {
            index = maxPage
        }
        this.getData(index, this.state.pageSize);
    }

    goPage = (current) => {
        let maxPage = Math.ceil(this.state.total / this.state.pageSize);
        if (current > maxPage) {
            current = maxPage
        }
        this.getData(current, this.state.pageSize);
    }

    pageRender = () => {
        let str = [];
        let maxPage = Math.ceil(this.state.total / this.state.pageSize);
        let current = this.state.current;
        if (current > 1) {
            str.push(<div className={'pageBtn'} onClick={this.goPrev}>上页</div>);
        }
        for (let i = 1; i <= maxPage; i++) {
            if (i < current + 3 && i > current - 3) {
                if(i === current){
                    str.push(<div className={'pageBtn current'} onClick={() => this.goPage(i)}>{i}</div>)
                }
                else{
                    str.push(<div className={'pageBtn'} onClick={() => this.goPage(i)}>{i}</div>)
                }

            } else if (i === current + 3 || i === current - 3) {
                str.push(<span>...</span>)
            }
        }
        if (current < maxPage) {
            str.push(<div className={'pageBtn'} onClick={this.goNext}>下页</div>)
        }
        return str
    }

    getDateStrDiff = (addSeconds) => {
        let date = new Date();
        date.setSeconds(date.getSeconds() + addSeconds);
        return `${date.toLocaleDateString().replace(/\//g, "-")} ${date.toTimeString().substr(0, 8)}`;
    }

    render() {
        return (
            <div className={"inventoryContainer"}>
                <div className={"mainContainer"} style={{marginTop:'10px'}}>
                    <div>
                        {
                            this.state.records.map((item,index)=>{
                                return(
                                    <div className={"numInfo refreshLight"} key={`numInfo_${item.id}`}>
                                        <div className={""}>
                                            {/*<div className={"invTitle"}>*/}
                                            {/*    电池模块*/}
                                            {/*</div>*/}
                                            <div className={"invRow"}>
                                                <div className={"infoTitle"}>核放单编号：</div>
                                                <div className={"infoDetail fSpecial"}>{item.passPortNo}</div>
                                            </div>
                                            <div className={"invRow"}>
                                                <div className={"infoTitle"}>车牌号：</div>
                                                <div className={"infoDetail fSpecial"}>{item.vehicleNo}</div>
                                            </div>
                                            {/*<div className={"invRow"}>*/}
                                            {/*    <div className={"infoTitle"}>集装箱号：</div>*/}
                                            {/*    <div className={"infoDetail"}>{item.containerNo}</div>*/}
                                            {/*</div>*/}
                                            <div className={"invRow"}>
                                                <div className={"infoTitle"}>过关时间：</div>
                                                <div className={"infoDetail fSpecial"}>{item.passTime}</div>
                                            </div>
                                            <div className={"invRow"}>
                                                <div className={"infoTitle"}>账册料号：</div>
                                                <div className={"infoDetail fSpecial"}>{item.gdsMtno}</div>
                                            </div>
                                            <div className={"invRow"}>
                                                <div className={"infoTitle"}>海关编码：</div>
                                                <div className={"infoDetail fSpecial"}>{item.gdecd}</div>
                                            </div>
                                            <div className={"invRow"}>
                                                <div className={"infoTitle"}>备案名称/型号：</div>
                                                <div className={"infoDetail fSpecial"}>{item.gdsNm}</div>
                                            </div>
                                            <div className={"invRow"}>
                                                <div className={"infoTitle"}>成品名称：</div>
                                                <div className={"infoDetail fSpecial"}>{item.productName}</div>
                                            </div>
                                            <div className={"invRow"}>
                                                <div className={"infoTitle"}>数量：</div>
                                                <div className={"infoDetail fSpecial"}>{item.dclQty}</div>
                                            </div>
                                            <div className={"invRow"}>
                                                <div className={"infoTitle"}>出入关类型：</div>
                                                <div className={"infoDetail"}></div>
                                            </div>
                                            <div className={`spShow ${item.ctype===0?"":"outSp"}`}></div>
                                            <div className={'inOut'}>{item.ctype===0?"入关":"出关"}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
                <div className={'fixedBottom'}>
                    <div className={'pageControl'}>

                        {
                            this.pageRender()
                        }

                        第{this.state.current}页，总计{this.state.total}条
                        每页
                        <select className={'pageSize'} value={this.state.pageSize} onChange={this.changeSize}>
                            <option value='10'>10</option>
                            <option value='20'>20</option>
                            <option value='50'>50</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    }
}

export default Inventory