import request from "../utils/request"
import {getSign} from "../utils/auth";

export function getInventoryInfo(){
    return request({
        url: "/customs/item/totalNumber",
        method: 'Get',
        params: getSign({})
    }).then((res)=>{
        return res;
    }).catch((res)=>{
        console.log(res);
        return Promise.reject()
    });
}

export function login(name, pwd){
    return request({
        url: "/user/login",
        method: 'get',
        params: getSign({
            name: name,
            pwd: pwd
        })
    }).then((res)=>{
        return res;
    }).catch((res)=>{
        console.log(res);
        return Promise.reject()
    });
}

export function getInventoryInfoDetail(gdsMtno, currentPage, pageSize){
    return request({
        url: "/customs/item/list",
        method: 'get',
        params: getSign({
            gdsMtno,
            currentPage,
            pageSize
        })
    }).then((res)=>{
        return res;
    }).catch((res)=>{
        console.log(res);
        return Promise.reject()
    });
}

export function getUser(code){
    return request({
        url: "/user/get",
        method: 'Get',
        params: getSign({
            code: code
        })
    }).then((res)=>{
        return res;
    }).catch(console.error);
}