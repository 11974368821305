import {getParam} from './common'
import {getUser} from'../server/api'

// const appId='wx15c5ad37b23c028e';
// const redirectUrl='http://192.168.50.65/login';
const appId='wxdf2ba92b16426393';
const redirectUrl='http://cloud.rct-power.com.cn/storage/inventory';
let authUrl='https://open.weixin.qq.com/connect/oauth2/authorize?appid='+appId+'&redirect_uri='
    +encodeURI(redirectUrl)
    +'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';

export function getUserInfo(){
    let userInfo = localStorage.getItem('rct_erp_site_user_ifo_w');
    if(userInfo){
        return JSON.parse(userInfo)
    }
    return null
}

export function saveUserInfo(userInfo){
    localStorage.setItem('rct_erp_site_user_ifo_w', userInfo);
}

export function removeUserInfo(){
    localStorage.removeItem('rct_erp_site_user_ifo_w');
}

export function hasRight() {
    let userInfo = getUserInfo();
    if (userInfo != null) {
        return userInfo.applyStatus === 1 || userInfo.applyStatus === 2;
    }
    return false;
}

export function checkUserInfo(path) {
    let userInfo = getUserInfo();
    if (userInfo != null) {
        if (userInfo.applyStatus === 1 || userInfo.applyStatus === 2) {
            if(path){
                window._ROUTER_.push(path);
            }
            return true;
        } else if (window.location.href.indexOf('getRight') > 0) {
            return true;
        } else {
            window._ROUTER_.push('getRight');
            return true;
        }
    }
    return false
}

export async function wechatAuth(path) {
    if (checkUserInfo(path)) {
        return true;
    }
    // debugger
    // //判断是否微信中
    // if (navigator.userAgent.indexOf('MicroMessenger') < 0) {
    //     return;
    // }
    const code = getParam('code')
    if (code) {
        let data = await getUser(code)
        if (data.result) {
            saveUserInfo(JSON.stringify(data.result));
            return checkUserInfo(path);
        }
        //     .then((data) => {
        //     if (data.result) {
        //         saveUserInfo(JSON.stringify(data.result));
        //         checkUserInfo();
        //     }
        // })
    } else {
        window.location.href = authUrl;
    }
    return false
}




