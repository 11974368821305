import CryptoJS from "crypto-js";
var TokenKey = 'Authorization';
export function getToken() {
    return localStorage.getItem(TokenKey);
}
export function setToken(token) {
    return localStorage.setItem(TokenKey, token);
}
export function removeToken() {
    return localStorage.removeItem(TokenKey);
}

export function getSign(obj) {
    obj.timestamp = new Date().getTime();
    let keyList = Object.keys(obj).sort();
    let sign = '';
    for (let i = 0; i < keyList.length; i++) {
        sign += (i > 0 ? '&' : '') + keyList[i] + '=' + obj[keyList[i]];
    }
    obj.sign = Encrypt(sign)
    return obj;
}

function Encrypt(word){ //加密
    var key = CryptoJS.enc.Utf8.parse("abcdefgh@1234567");
    //console.log("key:" + key);
    var srcs = CryptoJS.enc.Utf8.parse(word);
    //console.log("srcs:" + srcs);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
    return encrypted.toString();
}
function Decrypt(word){  //解密
    var key = CryptoJS.enc.Utf8.parse("abcdefgh@1234567");

    var decrypt = CryptoJS.AES.decrypt(word, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}
