import React from 'react';
import './index.css'
import {login} from '../server/api'
import {setToken} from '../utils/auth'
import {wechatAuth, hasRight} from '../utils/wechat';

class Login extends React.Component {
    constructor(props) {
        window._ROUTER_ = props.history
        super(props);
        this.state = {
            userName: '',
            password: ''
        };
    }

    componentDidMount() {
        wechatAuth('/')
        let that = this;
        document.onkeydown=(e)=> {
            if (e.code === 'Enter') {
                that.btnLogin();
            }
        }

    }

    valueChange = (key, e) => {
        let stateObject = {}
        stateObject[key] = e.target.value
        this.setState(stateObject)
    }

    btnLogin = () => {
        if(!this.state.userName){
            alert('请输入用户名');
            return
        }
        if(!this.state.password){
            alert('请输入密码');
            return
        }
        login(this.state.userName,this.state.password).then((data)=>{
            if(data){
                if(data.code === 0){
                    setToken(data.result);
                    this.props.history.push('/')
                }
                else{
                    alert(data.msg);
                }
            }
        })

        //this.props.history.push('/')
    }

    render() {
        return (
            <div className={"loginPage"}>
                <div className={"header"}>
                    <div className={"logo"}>
                        <img src={"/storage/logo.png"}/>
                    </div>
                </div>
                <div className={"secTilte"}>
                    阿诗特库存监控系统
                </div>
                <div className={"loginPanel"}>
                    <div className={"form-line"}>
                        <div className={"title"}>用户名</div>
                        <input type="text" className={"control"} onChange={(e)=>{this.valueChange("userName",e)}}/>
                    </div>
                    <div className={"form-line"}>
                        <div className={"title"}>密码</div>
                        <input type="password" className={"control"} onChange={(e)=>{this.valueChange("password",e)}}/>
                    </div>
                    <div className={"form-line"}>
                        <button className={"subBtn"} onClick={this.btnLogin}>登录</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login