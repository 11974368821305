import React from 'react';
import './index.css'
import {getInventoryInfo} from '../server/api'

class Inventory extends React.Component {
    constructor(props) {
        super(props);
        window._ROUTER_ = this.props.history
        this.state = {
            messageShow:0,
            dataTimeNow: this.getDateStrDiff(0),
            totalNum: 0,
            maxNum: 0,
            materialsInfo: [],
            records:[]
            //     [{
            //     cinvcode:'a1222s',
            //     cinvname:'a1222s',
            //     cinvstd:'a1222s',
            //     unit:'件',
            //     qty:'2000',
            // },{
            //     cinvcode:'a1222s',
            //     cinvname:'a1222s',
            //     cinvstd:'a1222s',
            //     unit:'件',
            //     qty:'2000',
            // }]
        };

        this.getData = this.getData.bind(this);
    }

    intervalInt= 0

    intervalRefresh= 0

    componentDidMount() {
        let that = this;
        this.intervalInt = setInterval(function () {
            var st = {
                dataTimeNow: that.getDateStrDiff(0)
            }
            if(that.state.messageShow>0) {
                st.messageShow = that.state.messageShow - 1;
            }
            that.setState(st)
        }, 1000)
        this.getData();
        this.intervalRefresh = setInterval(function () {
            that.getData();
        }, 120000)


    }

    componentWillUnmount() {
        clearInterval(this.intervalInt);
        clearInterval(this.intervalRefresh);
    }

    getData() {
        getInventoryInfo().then((data) => {
            if (data && data.code == 0) {
                let total = 0;
                let maxNum = 0;
                for (let i = 0; i < data.result.length; i++) {
                    total += data.result[i].dclQty;
                    maxNum = data.result[i].maxNum;
                }
                this.setState({
                    records: data.result,
                    messageShow: 3,
                    totalNum: total,
                    maxNum
                })
            }
        })
    }

    getDateStrDiff = (addSeconds) =>{
        let date = new Date();
        date.setSeconds(date.getSeconds()+addSeconds);
        return `${date.toLocaleDateString().replace(/\//g, "-")} ${date.toTimeString().substr(0, 8)}`;
    }

    goToDetail = (gdsMtno) => {
        this.props.history.push('/detailList', {gdsMtno:gdsMtno});
    }

    render() {
        return (
            <div className={"inventoryContainer"}>
                <div className={"header"}>
                    <div className={"logo"}>
                        <img src={"/storage/logo.png"}/>
                    </div>
                </div>
                <div className={"mainContainer"}>
                    <div className={"pageTitle"}>目前库存：<span className={'fSpecial'}>{this.state.totalNum}/{this.state.maxNum}</span><button className={"btnRefresh"} onClick={this.getData}>刷新</button></div>
                    <div className={"mTableInfo"}>
                        {
                            this.state.records.map((item,index) => {
                                return(
                                    <div className={this.state.messageShow>0?"numInfo refreshLight":"numInfo"} key={`dcl_${item.gdsMtno}`}
                                         onClick={(e)=>this.goToDetail(item.gdsMtno)}>
                                        <div className={""}>
                                            {/*<div className={"invTitle"}>*/}
                                            {/*    电池模块*/}
                                            {/*</div>*/}
                                            <div className={"invRow"}>
                                                <div className={"infoTitle"}>账册号/项号：</div>
                                                <div className={"infoDetail"}>ZC-001/XH-001</div>
                                            </div>
                                            <div className={"invRow"}>
                                                <div className={"infoTitle"}>账册料号：</div>
                                                <div className={"infoDetail"}>{item.gdsMtno}</div>
                                            </div>
                                            <div className={"invRow"}>
                                                <div className={"infoTitle"}>海关编码：</div>
                                                <div className={"infoDetail"}>{item.gdecd}</div>
                                            </div>
                                            <div className={"invRow"}>
                                                <div className={"infoTitle"}>备案名称/型号：</div>
                                                <div className={"infoDetail"}>{item.gdsNm}</div>
                                            </div>
                                            <div className={"infoDetail important"}>{item.dclQty}<span style={{color:'#BBB'}}></span></div>
                                        </div>


                                    </div>
                                )
                            })
                        }
                        <h1>{this.state.inventoryNum}</h1>
                    </div>
                </div>

                <h2 className={"now"}>现在是 {this.state.dataTimeNow}</h2>
            </div>
        );
    }
}

export default Inventory